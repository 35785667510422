//
// Home page filter search bar
//
<template>
	<form class="filter-bar" v-on:submit.prevent="handleSubmit">
    <!-- DIVISION DROPDOWN -->
    <div class="filtering-division">
      <div class="field-wrap select-style">
        <select name="masterformat" v-on:change="selectDivision($event)" v-model="filteredDivision" v-bind:disabled="!initialized">
          <option disabled="disabled" value="">CSI MasterFormat® Division</option>
          <option v-for="option in filterEmpty(divisionOptions)" v-bind:value="option.id">{{ formatNumbers(option.number) }} {{ option.title }}</option>
        </select>
      </div>
    </div>
    <!-- SECTION DROPDOWN -->
    <div class="filtering-section">
      <div class="field-wrap select-style" :class="{ 'disabled': !enabled || loading }">
        <select name="section" v-on:change="selectSection($event)" v-model="filteredSection" v-bind:disabled="!enabled  || loading">
          <option disabled="disabled" value="">Section</option>
          <option v-for="option in filterEmpty(sectionOptions)" v-bind:value="option.id">{{ formatNumbers(option.number) }} {{ option.title }}</option>
        </select>
      </div>
    </div>
    <!-- KEYWORD SEARCH -->
    <div class="filtering-keywords">
      <input type="text" placeholder="Brand, product type or name" v-model="keywords">
    </div>
    <div class="filtering-actions">
      <button type="submit" class="button" v-bind:disabled="!buttonEnabled">Go</button>
    </div>
	</form>
</template>

<script>
import { HTTP } from './http-common';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
  name: 'filterBar',

  data () {
    return {
    	divisionOptions: [],
      sectionOptions: [],
    	masterFormatOptions: [],
    	currentDivisionTree: [],
      loading: false,
      initialized: false
    };
  },

  computed: {

    // Set up Vuex states as two-way computed properties to use with v-model
    
    filteredDivision: {
      get() {
        return this.$store.state.filteredDivision;
      },
      set(value) {
        this.$store.commit('updateFilteredDivision', value);
      }
    },

    filteredSection: {
      get() {
        return this.$store.state.filteredSection;
      },
      set(value) {
        this.$store.commit('updateFilteredSection', value);
      }
    },

    filteredMasterFormats: {
      get() {
        return this.$store.state.filteredMasterFormats;
      },
      set(value) {
        this.$store.commit('updateFilteredMasterFormats', value);
      }
    },

    keywords: {
      get() {
        return this.$store.state.filterKeywords;
      },
      set(value) {
        this.$store.commit('updateFilterKeywords', value);
      }
    },

    // Local properties

    enabled: function() {
      return this.sectionOptions.length > 0;
    },
    buttonEnabled: function() {
      return this.keywords != '' || this.sectionOptions.length > 0;
    },
  },

  methods: {

    ...mapActions([
      'redirectToFilteredResults'
    ]),

    fetchMasterFormats() {
      this.initialized = false;

      HTTP.get('/masterformat')
        .then(response => {
          this.divisionOptions = response.data.data;
          this.initialized = true;
        })
        .catch(e => {
          alert(e);
        });
    },

    fetchMasterFormatsById(id) {
  		let index = this.masterFormatOptions.findIndex(function (obj) { return obj.id === id; });

  		if(index > -1) {
  			// use stored data
  			this.updateMasterFormatTree();
  		} else {
        this.loading = true;
  			// fetch the data
	      HTTP.get(`/masterformat/${id}`, {params:{depth:2}})
	        .then(response => {
	        	if(response.data.data.length) {
	        		this.masterFormatOptions.push(response.data.data[0]);
	        		this.updateMasterFormatTree();
	        	}
            this.loading = false;
	        })
	        .catch(e => {
	          alert(e);
	        });
	      }
    },

    // populate section options

    updateMasterFormatTree() {
    	let filteredDivision = this.filteredDivision;
    	let index = this.masterFormatOptions.findIndex(function (obj) { return obj.id === filteredDivision; });
    	if(index > -1) {
    		this.currentDivisionTree = this.masterFormatOptions[index];
        this.sectionOptions = this.currentDivisionTree.descendants;
    	} else {
    		this.currentDivisionTree = [];
        this.sectionOptions = [];
    	}      
    },

    // event handlers

    selectDivision($event) {
      this.fetchMasterFormatsById(this.filteredDivision);
      this.filteredMasterFormats = [this.filteredDivision];
      this.filteredSection = "";
    },

    selectSection($event) {
      this.filteredMasterFormats = [this.filteredDivision, this.filteredSection];
    },

    handleSubmit() {
      this.$nextTick(function(){
        this.redirectToFilteredResults();
      });
    },

    // utility functions

    filterEmpty(items) {
    	if(items) {
	    	return items.filter(function(item) {
	    		return item.count > 0;
	    	});
	    }
	    return items;
    },

    formatNumbers(str) {
      if(str) {
        let pattern = /( 00)? 00$/g;
        str = str.replace(pattern, '');
      }
      return str;
    },

    initializeFilters() {
      this.fetchMasterFormats();
    }
  },

  beforeMount() {
  	this.initializeFilters();
  }
};
</script>

<style lang="scss" scoped>

  .filter-bar {
    display: flex;
  }

  .filtering-division {
    margin-right: 16px;
    flex: 1 0 275px;

  }

  .filtering-section {
    flex: 1 0 275px;
    margin-right: 16px;
  }

  .filtering-keywords {
    flex: 1 1 275px;
    margin-right: 16px;

    input {
      font-family: 'Proxima N W15 Bold';
      font-size: 13px;
      font-weight: normal;
      height: 35px;
      border: 1px solid #87adbd;
      background: #f3f3f3;
      outline: 0;
      color: #3f99f8;
    }

    input::placeholder {
      color: #8EBAC5;
      font-family: Proxima N W15;
    }

    input:focus {
      border: 1px solid #0091F2;
    }
  }

  .filtering-actions {
    flex: 0 1 auto;

    button {
      min-height: 35px;
    }
  }
</style>
