/*
  store/index.js
*/
import axios from 'axios';
import { HTTP } from './../http-common';
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showFilterModal: false,
    filteredDivision: "",
    filteredSection: "",
    filterKeywords: "",
    filteredMasterFormats: [],
    filteredRatingSystems: [],
    filteredDisclosures: [],
    filteredEc3: [],
    filteredRegulations: [],
    filteredBrands: [],
    filteredBrandsAvailable: [],
    filteredBrandsVisible: [],
    filteredResults: null, // dynamic component that loads results html view
    filteredPageInfo: {},
    loadingResults: false,
    errors: [],
    cancelTokenSource: null
  },

  mutations: {
    setLoadingResults (state, bool) {
      bool = (typeof bool === "undefined") ? true : bool;
      state.loadingResults = bool;
    },

    toggleFilterModal(state) {
      state.showFilterModal = !state.showFilterModal;
    },

    closeFilterModal(state) {
      state.showFilterModal = false;
    },

    updateFilteredDivision(state, division) {
      state.filteredDivision = division;
    },

    updateFilteredSection(state, section) {
      state.filteredSection = section;
    },

    updateFilteredMasterFormats(state, masterFormats) {
      state.filteredMasterFormats = masterFormats;
    },

    updateFilteredRatingSystems(state, ratingSystems) {
      state.filteredRatingSystems = ratingSystems;
    },

    updateFilteredDisclosures(state, disclosures) {
      state.filteredDisclosures = disclosures;
    },

    updateFilteredEc3(state, ec3) {
      state.filteredEc3 = ec3;
    },

    updateFilteredRegulations(state, regulations) {
      state.filteredRegulations = regulations;
    },

    updateFilteredBrands(state, brands) {
      state.filteredBrands = brands;
    },

    updateFilteredBrandsAvailable(state, brands) {
      state.filteredBrandsAvailable = brands;
    },

    updateFilteredBrandsVisible(state, brands) {
      state.filteredBrandsVisible = brands;
    },

    updateFilterKeywords(state, keywords) {
      state.filterKeywords = keywords;
    },

    updateFilteredResults(state, html) {
      // dynamic component that loads results html view
      state.filteredResults = Vue.component('filtered-results', {
        template: html,
        methods: {
          handlePagination: function($event) {
            this.$store.dispatch('fetchFilteredResults', $event.target.href);
          }
        }
      });
    },

    updateFilteredPageInfo(state, pageInfo) {
      state.filteredPageInfo = pageInfo;
    },

    clearFilteredDivision(state) {
      state.filteredDivision = "";
    },

    clearFilteredSection(state) {
      state.filteredSection = "";
    },

    clearFilteredBrands(state) {
      state.filteredBrands = [];
    },

    clearFilteredBrandsAvailable(state) {
      state.filteredBrandsAvailable = [];
    },

    clearFilteredResults(state) {
      state.filteredResults = null;
    },

    handleError: (state, error) => {
      if (error.response) {
        if (error.response.message) {
          state.errors.push(error.response.message);
        } else {
          state.errors.push('Error: '+error.response.status+' - '+error.response.statusText);
        }
      } else {
        // Something happened in setting up the request that triggered an Error
        state.errors.push('An error has occured');
        console.log('Error:', error);
      }
    },

    setErrorMessage (state, message) {
      state.errors.push(message);
    },

    clearErrors(state) {
      state.errors = [];
    },

    setCancelToken (state, source) {
      state.cancelTokenSource = source;
    }
  },

  actions: {
    fetchFilteredResults ({commit, state}, payload) {
      // check for previous request and cancel or store new token.
      // https://github.com/axios/axios#cancellation
      if(state.cancelTokenSource !== null) {
        state.cancelTokenSource.cancel('User input has triggered a new request.');
      }

      commit('setCancelToken', axios.CancelToken.source());

      // reset UI
      commit('setLoadingResults');
      commit('clearFilteredResults');
      commit('clearFilteredBrandsAvailable');
      commit('closeFilterModal');

      // set up request

      let url = '/search/json';

      let params = {
        division: state.filteredDivision,
        number: state.filteredMasterFormats.join(),
        rating: state.filteredRatingSystems.join(),
        disclosure: state.filteredDisclosures.join(),
        ec3: state.filteredEc3.join(),
        regulation: state.filteredRegulations.join(),
        brand: state.filteredBrands.join(),
        q: state.filterKeywords
      };

      if(payload && payload.length) {
        // just call the url directly for pagination
        url = payload;
        params = null;
      }

      HTTP.get(url, { params: params, cancelToken: state.cancelTokenSource.token })
        .then(response => {
          commit('updateFilteredResults', response.data.results);
          commit('updateFilteredBrandsAvailable', response.data.brands);
          commit('updateFilteredBrandsVisible', response.data.brandsVisible);
          commit('updateFilteredPageInfo', response.data.paginate);
          commit('setLoadingResults', false);
          commit('clearErrors');

          // Fire an event so jQuery knows the results loaded
          let resultsEvent = new Event('resultsUpdated', { bubbles: true });
          document.dispatchEvent(resultsEvent);

          console.log('Results loaded: ', params);
        }).catch(e => {
          if (axios.isCancel(e)) {
            console.log('Request canceled.', e.message);
          } else {
            commit('handleError', e);
            commit('setLoadingResults', false);
            commit('clearFilteredResults');
          }
        });
    },

    redirectToFilteredResults({commit, state}) {
      let url = '/search';
      let params = {
        number: state.filteredMasterFormats.join(),
        q: state.filterKeywords
      };
      let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      window.location.href = url+'?'+query;
    },

    paginateFilteredResults ({commit, state}, payload) {
      console.log(payload);
    },


  }
});
