import "babel-polyfill";
import Vue from 'vue';
import store from './store';
import App from './App';
import VueAnalytics from 'vue-analytics';

// Only fire GA on production 
var gaID = '';
if (window.location.hostname == 'transparencycatalog.com') {
	gaID = 'UA-85315823-1';
}
console.log('Using GA ID: ' + gaID);

Vue.use(VueAnalytics, {
  id: gaID
});

new Vue({
  store,
  el: '#wrapper',
  delimiters: ['${', '}'],
  components: {
  	App
  }
});
