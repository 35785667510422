<template>
  <transition name="modal">
    <div class="modal-mask scroll" v-bind:class="classNames.modalMask" v-if="show">
        <div class="modal-wrapper" v-bind:class="classNames.modalWrapper">
          <div class="modal-container" v-bind:class="classNames.modalContainer">
            <button class="modal-close-button" v-on:click="close">
              Close
            </button>
            <slot></slot>
          </div>
        </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'modal',

  data () {
    return {
      offsetY: 0,
    }
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },

    classNames: {
      type: Object,
      default: function () {
        return {
          modalMask: [],
          modalWrapper: [],
          modalContainer: []
        };
      }
    }
  },

  watch : {
    show(visible) {
      if(visible) {
        this.offsetY = window.pageYOffset;
        let scrollBarWidth = window.innerWidth - document.body.offsetWidth;

        document.body.classList.add('modal-open');
        document.body.style.marginRight = scrollBarWidth+'px';
        document.body.style.marginTop = -Math.abs(this.offsetY)+'px';

      } else {

        document.body.classList.remove('modal-open');
        document.body.style.marginRight = null;
        document.body.style.marginTop = null;

        window.scrollTo(0, this.offsetY);
      }
    }
  },

  mounted: function () {
    document.addEventListener('keydown', (e) => {
      if (this.show && e.keyCode == 27) this.close();
    });
  },

  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style lang="scss">
  body.modal-open { overflow: hidden; }
</style>

<style lang="scss" scoped>
.modal-mask {
  display: block;
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity .3s ease;

  &.scroll {
    overflow: auto;
  }

  &.white {
    background-color: #fff;
  }
}

.modal-wrapper {
  padding: 60px 0;
  display: block;
  vertical-align: middle;
}

.modal-container {
  position: relative;
  width: 830px;
  margin: 0px auto;
  padding: 16px 0 24px 0;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: opacity .3s ease, transform .3s ease;
  font-family: 'Proxima N W15', Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 13px;
  height: 500px;
  overflow: scroll;

  &.brands {
    width: 830px;
    background: #fff;
    box-shadow: 4px 4px 52px 8px rgba(0, 0, 0, 0.5)
  }

  .modal-header p {
    color: #000;
  }
}

.modal-close-button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: transparent;
  background-image: url(/assets/images/close-button.svg);
  background-repeat: no-repeat;
  width: 94px;
  height: 50px;
  background-position: center;
  border: none;
  text-indent: -99999px;

  &:hover {
    opacity: 0.8;
  }
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  //-webkit-transform: scale(1.1);
  //transform: scale(1.1);
}

</style>
