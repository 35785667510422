import { mapMutations } from 'vuex';
import FilterBar from './FilterBar.vue';
import FilterNav from './FilterNav.vue';
import FilterResults from './FilterResults.vue';
import FilterModal from './FilterModal.vue';

export default {
  name: 'app',

  data(){
    return {}
  },

  methods : {
    ...mapMutations([
      'toggleFilterModal'
    ]),

    displayFilterModal(event, string){
      if (event) event.preventDefault();
      this.toggleFilterModal(string);
    }
  },

  components: {
    FilterBar,
    FilterNav,
    FilterResults,
    FilterModal
  }
}
