<template>
  <modal
  :show="showFilterModal"
  :class-names="classNames"
  @close="handleClose()"
  v-cloak>
    <div class="modal-header">
      <h2>These are all the brands with products in the MasterFormat® sections selected.</h2>
      <p>To further filter results, select only the brands you want included.</p>
    </div>
    <div class="modal-body">
      <div class="modal-brands-listing">
        <div v-for="(col, index) in batch(filteredBrandsAvailable, 4)" class="brand-col">
          <div v-for="(brand, index) in col" class="field-wrap checkbox-style" :class="levelClass(brand)">
            <input type="checkbox"
              :name="'brand-'+brand.id"
              :id="'brand-'+brand.id"
              :value="brand.id"
              v-model="filteredBrands">
            <label :for="'brand-'+brand.id">{{ brand.title }}</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer-fixed">
      <div class="modal-footer-container">
        <button class="button btn-secondary" @click.prevent="handleSave()">Save brands</button>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Modal from './Modal.vue';

export default {
  name: 'filterModal',

  data() {
    return {
      classNames: {
        modalMask: [''],
        modalWrapper: ['brands'],
        modalContainer: ['brands']
      }
    }
  },

  computed: {
    ...mapState([
      'showFilterModal',
      'filteredDivision',
      'filteredBrandsAvailable'
    ]),

    filteredBrands: {
      get() {
        return this.$store.state.filteredBrands;
      },
      set(value) {
        this.$store.commit('updateFilteredBrands', value);
      }
    }
  },

  methods: {
    ...mapMutations([
      'toggleFilterModal'
    ]),

    ...mapActions([
      'fetchFilteredResults'
    ]),

    handleClose(event){
      if(event) event.preventDefault();
      this.toggleFilterModal();
    },

    handleSave(event){
      if(event) event.preventDefault();
      this.toggleFilterModal();
      this.fetchFilteredResults();
    },

    levelClass(brand) {
      switch(brand.level.slug) {
        case "freemium":
          return "freemium";
          break;
        case "level-1":
          return "featured level1 " + brand.columns;
          break;
        case "level-2":
          return "featured level2";
          break;
        case "level-3":
          return "featured level3";
          break;
        default:
          return "";
          break;
      }
    },

    // split an array into equal sized chunks
    batch(items, size) {
      let batchSize = Math.ceil(items.length/size);

      return this.chunk(items, batchSize);
    },

    // split an array into chunks of a given size
    chunk(items, size) {
      let result = [];
      let index = 0;

      if (size < 1 || !Array.isArray(items)) {
        return [];
      }

      while (index < items.length) {
          result.push(items.slice(index, index += size));
      }

      return result;
    }
  },

  components: {
    Modal
  }
}
</script>

<style lang="scss" scoped>

.modal-header {
  padding: 15px 30px 0 30px;
}
.modal-body {
  padding: 25px 30px 130px;
}

.modal-footer-fixed {
  position: fixed;
  top: 560px;
  left: 0;
  right: 0;

  .modal-footer-container {
    width: 830px;
    margin: 0 auto;
    padding: 11px 17px 17px 17px;
    text-align: right;
    position: relative;
    background: #fff;
    //border-top: 1px solid #dededd;
    box-shadow: 0 -9px 0 #fff, 4px 18px 52px 8px rgba(0, 0, 0, 0.5);
    // this is the curved shadow element
    /*    
    &::before {
      content: "";
      position:absolute;
      z-index: -1;
      width:96%;
      top: 0;
      height: 10px;
      left: 2%;
      border-radius: 100px / 5px;
      box-shadow:0 0 18px rgba(0,0,0,0.15);
    }
    */
  }
}

h2 {
  font-family: 'DIN Next W01 Medium';
  font-size: 19px;
  line-height: 25px;
  margin-bottom: 15px;
  color: #0099FF;
  margin-top: 0;
}

.modal-brands-listing {
  padding-top: 30px;
  border-top: 1px solid #dededd;
  display: flex;

  .brand-col {
    flex: 0 0 25%;
    padding-right: 15px;
  }

  label {
    display: block;
    line-height: 1.1 !important;
    margin: 0 0 10px !important;
  }

  .freemium label {
    color: #134fbd;
    font-family: 'Proxima N W15';
    font-size: 11px !important;

    &::before, &::after {
      top: 2px !important;
    }
  }

  .level1  label {
    font-size: 12px !important;
    color: #134fbd;

    &::before, &::after {
      top: 1px !important;
    }
  }

  .level1.material {
    background: none;
    label {
      font-family: 'Proxima N W15' !important;
    }
  }

  .level2  label {
    color: #1BAD0C;
    font-size: 15px !important;

    &::before, &::after {
      top: 2px !important;
    }
  }


  .level3 label {
    position: relative;
    padding: 4px;
    margin-left: -4px !important;
    border: 1px solid #17BF1D;
    border-radius: 3px;
    color: #17BF1D;
    font-size: 15px !important;
    font-family: 'Proxima N W15 Bold';

    &::before, &::after {
      top: 6px !important;
      left: 5px !important;
    }
  }

  .level3:first-child {
    margin-top: -5px;
  }

  .featured label {
    font-family: 'Proxima N W15 Bold', Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: normal;
  }
}

</style>
