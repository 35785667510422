/*

  @todo:

  - Fix disabled states before brands modal can be clicked,
    or maybe even auto-close based on loading state.

*/
<template>
  <div class="filter-nav">
    <div class="filter-heading">
      <h3>CSI MasterFormat®</h3>
      <a href="#" v-on:click.prevent="clearFilters()">Clear filters</a>
    </div>
    <div class="filters">
      <!-- DIVISION DROPDOWN -->
      <div class="field-wrap select-style filter-divisions">
        <select name="masterformat" v-on:change="selectDivision($event)" v-model="filteredDivision" v-bind:disabled="!initialized">
          <option value="">Select Division</option>
          <option v-for="option in filterEmpty(divisionOptions)" v-bind:value="option.id">{{ formatNumbers(option.number) }} {{ option.title }}</option>
        </select>
      </div>
      <!-- LOADER -->
      <div v-show="loading" v-cloak class="loading-text loading-small text-center">Loading filters</div>
      <!-- FILTER GROUPS -->
      <perfect-scrollbar class="filter-scroll-container" :options="scrollOptions">
        <div class="filter-groupings">
          <div v-for="group in currentDivisionTree.descendants" v-if="group.count > 0" class="filter-group collapsible" :class="{'open': filteredSection == group.id }">
            <div class="group-heading">
              <a :href="'#group-'+group.id" v-collapse="filteredSection == group.id" :data-state="filteredSection == group.id ? 'open' : 'closed'" :data-group="group.id" :class="{'open': filteredSection == group.id, 'active': divisionOnlyEnabled }" @click="toggleGroup($event)">
                {{ formatNumbers(group.number) }} {{ group.title }}
              </a>
               <a href="#" :data-state="filteredSection == group.id ? 'closed' : 'open'" :data-group="group.id" class="toggle" @click="toggleAll($event)">Clear all</a>
            </div>

            <div class="group-content collapse" :class="{'show': filteredSection == group.id }" :id="'group-'+group.id">
              <ul class="filter-checkboxes">
                <!-- Adds default option if no descendants -->
                <li v-if="filterEmpty(group.descendants).length === 0">
                  <div class="field-wrap checkbox-style">
                    <input type="checkbox"
                      :id="'filter1-'+group.id"
                      :value="group.id"
                      :data-group="group.id"
                      :data-parent="group.id"
                      :data-level="group.level"
                      :data-title="group.number + ' ' + group.title"
                      v-model="filteredMasterFormats"
                      v-on:click="toggleCheckbox($event)">
                    <label :for="'filter1-'+group.id">{{ formatNumbers(group.number) }} {{ group.title }}</label>
                  </div>
                </li>
                <!-- Begin descendant tree -->
                <li v-for="node in filterEmpty(group.descendants)">
                  <div class="field-wrap checkbox-style">
                    <input type="checkbox"
                      :id="'filter1-'+node.id"
                      :value="node.id"
                      :data-group="group.id"
                      :data-parent="node.id"
                      :data-level="node.level"
                      :data-title="node.number + ' ' + node.title "
                      v-model="filteredMasterFormats"
                      v-on:click="toggleCheckbox($event)">
                    <label :for="'filter1-'+node.id" class="{'displayNormalText': node.displayTextNormal == 1}">{{ formatNumbers(node.number) }} {{ node.title }}</label>
                  </div>
                  <ul v-if="node.descendants.length" class="filter-checkboxes">
                    <li v-for="node2 in filterEmpty(node.descendants)">
                      <div class="field-wrap checkbox-style">
                        <input type="checkbox"
                          :id="'filter2-'+node2.id"
                          :value="node2.id"
                          :data-group="group.id"
                          :data-parent="node.id"
                          :data-level="node2.level"
                          :data-title="node2.number + ' ' + node2.title"
                          v-model="filteredMasterFormats"
                          v-on:click="toggleCheckbox($event)">
                        <label :for="'filter2-'+node2.id" :class="{'displayNormalText': node2.displayTextNormal == 1}">{{ formatNumbers(node2.number) }} {{ node2.title }}</label>
                      </div>
                      <ul v-if="node2.descendants.length" class="filter-checkboxes">
                        <li v-for="node3 in node2.descendants">
                          <div class="field-wrap checkbox-style">
                            <input type="checkbox"
                            :id="'filter3-'+node3.id"
                            :value="node3.id"
                            :data-group="group.id"
                            :data-parent="node.id"
                            :data-level="node3.level"
                            :data-title="node3.number + ' ' + node3.title"
                            v-model="filteredMasterFormats"
                            v-on:click="toggleCheckbox($event)">
                            <label :for="'filter3-'+node3.id" :class="{'displayNormalText': node3.displayTextNormal == 1}">{{ formatNumbers(node3.number) }} {{ node3.title }}</label>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </perfect-scrollbar>

      <div class="filter-search">
        <div class="filter-heading">
          <h3 :class="{ 'disabled': !enabled }">Keyword</h3>
        </div>
        <div class="input-group">
          <input type="text" placeholder="Brand, product type, name" v-model="keywords" :disabled="!enabled" v-on:keypress.13.prevent="submitKeywords()">
          <button type="button" class="button btn-secondary" v-on:click.prevent="submitKeywords()" v-bind:disabled="!buttonEnabled">Go</button>
        </div>
      </div>

      <div class="filter-brands">
        <div class="filter-heading">
          <h3 :class="{ 'disabled': !enabled || !brandsEnabled }">Brands</h3>
          <button type="button" class="button btn-simple" v-on:click.prevent="toggleFilterModal" :disabled="!enabled || !brandsEnabled">Select brands</button>
        </div>
      </div>
      <div class="filter-ratings">
        <div class="collapsible" :class="{ 'disabled': !enabled }">
          <h3>
            <a href="#filter-ratings" class="collapsible-trigger filter-ratings-label" data-toggle="collapse">
              Rating systems
              <span class="help-popup tooltip" v-bind="tooltipContent"></span>
            </a>
          </h3>
          <div class="collapse" id="filter-ratings">
            <ul class="filter-checkboxes">
              <li v-for="option in ratingSystemOptions">
                <div v-if="option.id != ''" class="field-wrap checkbox-style">
                  <input type="checkbox"
                    :value="option.id"
                    :id="'rating-'+option.id"
                    v-model="filteredRatingSystems"
                    v-on:click="toggleRatingSystems($event)">
                  <label :for="'rating-'+option.id"><span v-html="option.title"></span></label>
                </div>
                <div v-else class="field-wrap group-style">
                  <label><span v-html="option.title"></span></label>
                </div>
              </li>
            </ul>
            <a href="/rating-systems" target="_blank" class="filter-link">Check disclosure credit eligibility</a>
          </div>
        </div>
      </div>
      <!-- EPDS & EC3 -->
      <div class="filter-ec3">
        <div class="collapsible" :class="{ 'disabled': !enabled }">
          <h3>
            <a href="#filter-ec3" class="collapsible-trigger filter-ec3-label" data-toggle="collapse">EPD types / embodied carbon</a>
          </h3>
          <div class="collapse" id="filter-ec3">
            <ul class="filter-checkboxes">
              <li>
                <div class="field-wrap group-style"><label><span>Type</span><span class="help-popup tooltip" v-bind="epdTooltipContent"></span></label></div>
              </li>

              <!-- EPDs -->
              <li v-for="option in epdOptions">
                <div v-if="option.id != ''" class="field-wrap checkbox-style">
                  <input type="checkbox"
                    :value="option.id"
                    :id="'disclosure-'+option.id"
                    :data-group="'disclosure-'+option.id"
                    :disabled="option.id == regulation-lbc"
                    v-model="filteredDisclosures"
                    v-on:click="toggleDisclosures($event)">
                  <label :for="'disclosure-'+option.id"><span v-html="option.title"></span></label>
                  <span v-if="option.id == 'healthProductDeclaration'" class="help-popup tooltip hpd" v-bind="disclosureTooltipContent"></span>
                </div>
                <div v-else class="field-wrap group-style">
                  <label><span v-html="option.title"></span></label>
                </div>
                <ul v-if="option.descendants">
                  <li v-for="node in option.descendants">
                    <div class="field-wrap checkbox-style">
                      <input type="checkbox"
                        :value="node.id"
                        :id="'disclosure-'+node.id"
                        :data-parent="'disclosure-'+option.id"
                        v-model="filteredDisclosures"
                        v-on:click="toggleDisclosures($event)">
                      <label :for="'disclosure-'+node.id"><span v-html="node.title"></span></label>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>

            <!-- EC3 -->
            <ul class="filter-checkboxes">
              <li>
                <div class="field-wrap group-style"><label><span>EC3 embodied carbon</span><span class="help-popup tooltip" v-bind="ec3TooltipContent"></span></label></div>
              </li>

              <li v-for="option in ec3Options">
                <div v-if="option.id != ''" class="field-wrap checkbox-style">
                  <input type="checkbox"
                    :value="option.id"
                    :id="'ec3-'+option.id"
                    v-model="filteredEc3"
                    v-on:click="toggleEc3($event)"
                    >
                  <label :for="'ec3-'+option.id"><span :class="'l ec3-'+option.id"></span><span v-html="option.title"></span></label>
                </div>
                <div v-else class="field-wrap group-style">
                  <label><span :class="'ec3-'+option.id"></span><span v-html="option.title"></span></label>
                </div>
              </li>
            </ul>

            <!-- Regulations -->
            <ul class="filter-checkboxes">
              <li>
                <div class="field-wrap group-style"><label><span>Regulations</span><span class="help-popup tooltip" v-bind="regulationsTooltipContent"></span></label></div>
              </li>

              <li v-for="option in regulationOptions">
                <div class="field-wrap checkbox-style">
                  <input type="checkbox"
                    :value="option.id"
                    :id="'regulation-'+option.id"
                    v-model="filteredRegulations"
                    v-on:click="toggleRegulations($event)">
                  <label :for="'regulation-'+option.id"><span v-html="option.title"></span></label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Material ingredient disclosures -->
      <div class="filter-disclosures">
        <div class="collapsible" :class="{ 'disabled': !enabled }">
          <h3>
            <a href="#filter-disclosures" class="collapsible-trigger filter-disclosures-label" data-toggle="collapse">
              Material disclosure types
            </a>
          </h3>
          <div class="collapse" id="filter-disclosures">
            <ul class="filter-checkboxes">
              <li v-for="option in materialOptions">
                <div v-if="option.id != ''" class="field-wrap checkbox-style">
                  <input type="checkbox"
                    :value="option.id"
                    :id="'disclosure-'+option.id"
                    :data-group="'disclosure-'+option.id"
                    :disabled="option.id == regulation-lbc"
                    v-model="filteredDisclosures"
                    v-on:click="toggleDisclosures($event)">
                  <label :for="'disclosure-'+option.id"><span v-html="option.title"></span></label>
                  <span v-if="option.id == 'healthProductDeclaration'" class="help-popup tooltip hpd" v-bind="disclosureTooltipContent"></span>
                </div>
                <div v-else class="field-wrap group-style">
                  <label><span v-html="option.title"></span></label>
                </div>
                <ul v-if="option.descendants">
                  <li v-for="node in option.descendants">
                    <div class="field-wrap checkbox-style">
                      <input type="checkbox"
                        :value="node.id"
                        :id="'disclosure-'+node.id"
                        :data-parent="'disclosure-'+option.id"
                        v-model="filteredDisclosures"
                        v-on:click="toggleDisclosures($event)">
                      <label :for="'disclosure-'+node.id"><span v-html="node.title"></span></label>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="filter-clear">
      <a href="#" v-on:click.prevent="clearFilters()">Clear filters</a>
    </div>
    <div class="all-brands">
      <h3><a href="/brands" class="dark-blue">All brands at a glance</a></h3>
      <ul class="anchor-nav">
        <li><a href="/brands/#companies1"># A – B</a></li> 
        <li><a href="/brands/#companies2">C – D</a></li> 
        <li><a href="/brands/#companies3">E – K</a></li> 
        <li><a href="/brands/#companies4">L – O</a></li> 
        <li><a href="/brands/#companies5">P – S</a></li> 
        <li><a href="/brands/#companies6">T – Z</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import { HTTP } from './http-common';
import { mapState, mapMutations, mapActions } from 'vuex';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';

export default {
  name: 'filterNav',

  props: {
    requestData: {
      type: Object,
      required: false
    }
  },

  data () {
    return {
      request: this.requestData,
      divisionOptions: [],
      masterFormatOptions: [],
      filteredMasterFormatTitles: [],
      currentDivisionTree: [],
      ratingSystemOptions: [],
      disclosureOptions: [],
      ec3Options: [],
      epdOptions: [],
      materialOptions: [],
      regulationOptions: [],
      divisionTreeOpen: false,
      loading: false,
      initialized: false,
      scrollOptions: {
        minScrollbarLength: 80
      },
    };
  },

  watch: {

    // Watch for visible brands to be updated so we can throw some GA events 
    filteredBrandsVisible() {
      for (let i = 0; i < this.filteredBrandsVisible.length; i++) {
        this.sendGaEvent('search', 'appeared', this.filteredBrandsVisible[i].title);
      }
    }
    
  },

  computed: {

    // Set up Vuex states as two-way computed properties to use with v-model
    tooltipContent() {
      return {
        title: `
          <div class='tooltip-hpd'>
            <h1>Sustainable Minds reviews all disclosures for rating systems eligibility in these listing types:</h1>
            <p><strong>Level 3: Featured Brand</strong></p>
            <p><strong>Level 2: Standard</strong></p>
            <p>&nbsp;</p>
            <p><strong>Companies with Level 1: FREE material ingredient listings</strong> and their disclosures are displayed in the results. However, Sustainable Minds does not review these disclosures to determine actual eligibility for each rating system.
            <p>&nbsp;</p>
            <p>If you see a brand you like who has not yet added its products, go to their FREE listing and click <strong>'<a href="mailto:sales@sustainableminds.com?subject=Tell%20me%20more%20about%20the%20SM%20Transparency%20Catalog&body=I%20like%20what%20I%E2%80%99m%20seeing.%20Please%20contact%20me%20to%20discuss%20how%20we%20can%20make%20our%20products%20with%20transparency%20information%20findable%20and%20the%20transparency%20information%20understandable.%0D%0A%0D%0A%5BYour%20Name%2C%20title%2C%20contact%20info%5D%0D%0A%0D%0AThank%20you.%0D%0A%0D%0Awww.transparencycatalog.com%0D%0AAll%20brands%20making%20credibly%20greener%20%26%20healthier%20products%20for%20the%20built%20environment%0D%0A">Ask the manufacturer</a> to add its products'</strong> to let them know about the benefits of doing so to you both!</p>
          </div>
        `,
      }
    },
    regulationsTooltipContent() {
      return {
        title: `
          <div class='tooltip-hpd'>
            <h1>Regulations</h1>
            <h2>Buy Clean California Act</h2>
            <p>The <strong>Buy Clean California Act</strong> deploys CA’s substantial purchasing power to buy low-carbon products by requiring contractors bidding on state infrastructure and construction projects to disclose the greenhouse gas emissions for:</p>
            <ul>
              <li><strong>Hot-rolled structural steel sections</strong></li>
              <li><strong>Hollow structural sections</strong></li>
              <li><strong>Steel plate</strong></li>
              <li><strong>Concrete reinforcing steel</strong></li>
              <li><strong>Flat glass</strong></li>
              <li><strong>Light-density mineral wool board insulation</strong></li>
              <li><strong>Heavy-density mineral wool board insulation</strong></li>
            </ul>
            <p>To comply with Buy Clean rules, contractors need to select materials from manufacturers who provide EPDs (using reference PCRs) for their products and facilities and that have a global warming potential (GWP) that does not exceed the limit set by the CA Department of General Services.</p>
            <p class="red"><br/><strong>Starting July 1, 2022, awarding authorities will determine GWP limit compliance of eligible materials using EPDs.</strong></p>
            <p>&nbsp;</p>
            <p>We're making it easy for contractors to find these products with EPDs. <a href="https://www.dgs.ca.gov/PD/Resources/Page-Content/Procurement-Division-Resources-List-Folder/Buy-Clean-California-Act#@ViewBag.JumpTo" target="_blank">Learn more ></a></p>
          </div>
        `,
      }
    },
    disclosureTooltipContent() {
      return {
        title: `
          <div class='tooltip-hpd'>
            <h1>Health Product Declaration</h1>
            <h2>Additional restricted substances list</h2>
            <p><strong>Use the filters to find products that do NOT contain chemicals on these lists.</strong></p>
            <br/>
            <p><strong>HPD v2.3 includes additional hazard listings that ingredients are screened against. </strong>Complementary to the hazard listings displayed on an HPD, they indicate chemicals restricted under programs relevant to HPD users and provide a supplemental perspective for interpreting and hazard screening results to supporting decision-making. <a href='https://www.hpd-collaborative.org/additional-listings/'' target='_blank'>Learn more ></a>
            <h2>Perkins&Will Precautionary List</h2>
            <p><strong>Created by Perkins&Will, their firm-wide mandate to review products against this list for every project is twofold.</strong> First, it raises awareness and understanding of chemicals of concern; second, it provides a designer with the framework to make an alternate selection, after considering all factors relevant to design and performance and advising the owner. 
            <br/><a href='https://transparency.perkinswill.com/lists/precautionary-list' target='_blank'>Learn more ></a></p>
            <p>Using the results reported in each HPD, this filter displays all HPDs that meet these criteria:</p>
            <ul> 
              <li>100 ppm</li>
              <li>Characterized</li>
              <li>Screened</li>
              <li>Identified</li>
              <li><em>Contains no P&W PL chemicals</em></li>
            </ul>
            <h2>Living Building Challenge (LBC) – Red List</h2>
            <p><strong>Created by the International Living Future Institute (ILFI), this list is useful to users who use HPDs to screen products for LBC criteria,</strong> and to MFRs researching their products and publishing HPDs for LBC participation. The list represents the 'worst in class' materials, chemicals, and elements known to pose serious risks to human health and the greater ecosystem. <a href='https://living-future.org/red-list/' target='_blank'>Learn more ></a></p>
            <h2>Cradle to Cradle Certified Products – Restricted Substances List (RSL)</h2>
            <p><strong>Created by the Cradle to Cradle Products Innovation Institute, this list applies to all products seeking C2C certification or a Material Health Certificate.</strong> This list is useful to users to prescreen or gain awareness of product contents by seeing the RSL on the HPD to MFRs to prescreen their verified content inventory before moving on to certification. <a href='https://pharos.habitablefuture.org/hazard-lists/358#hazards-panel' target='_blank'>Learn more ></a></p>
          </div>
        `,
      }
    },
    ec3TooltipContent() {
      return {
        title: `
           <div class='tooltip-ec3'>
              <img src='/assets/images/ec3-tooltip-v2.png' width='117' alt='EC3' class='logo' />
              <p style='color: red;'><strong>EC3 is a cloud-based tool with constant data updates. EC3 results in the Transparency Catalog are updated monthly and can change.</strong></p>
              <hr />

              <div class='left'>
                  <p><strong>The Embodied Carbon in Construction Calculator (EC3) tool – from Building Transparency (incubated by the Carbon Leadership Forum and industry partners –</strong> is designed to enable the building industry to transparently measure, compare, and reduce embodied carbon emissions from construction materials.</p>
                  <p><strong>How the EC3 methodology uses EPD data:</strong> Using the global warming potential (embodied carbon) results from the manufacturing life cycle stage, five attributes of the data are evaluated to determine its variability. Is it specific to the: MFR plant, product, supply chain, batch? Applying a ‘burden of the doubt’ calculation (opposite of ‘benefit of the doubt’ when the best outcome is given without proof), a +/- uncertainty value (penalty) is added based on yes/no. When the data is not in the EPD, an uncertainty value is applied.</p>
                  <p><strong>NOTE: Results are not precise, they are directionally correct and should be used with in that in mind.</strong> Results may not always identify low embodied carbon products due to non-specific data in the EPD.</p>
                  <div class='labels'>
                      <span class='l l5'>80th+</span> <p>Cannot be identified to be among the <strong>80%</strong> of related products with the lowest EC and may be in the 20% highest due to high reported EC results plus some high uncertainty.</p>
                      <span class='l l4'>60th+</span> <p>Not known to be among the <strong>60%</strong> of related products with the lowest EC, but likely not in the 20% highest. Uncertainty may be high due to mostly non-specific data in the EPD. Industry-wide EPDs are assumed to be in this range.</p>
                      <span class='l l3'>60th</span> <p>Among the <strong>60%</strong> of related products with the lowest EC
          </p>
                      <span class='l l2'>40th</span> <p> Among the <strong>40%</strong> of related products with the lowest EC
          </p>
                      <span class='l l1'>20th</span> <p>Among the <strong>20%</strong> of related products with the lowest EC
          </p>
                      <span class='l'></span> <p><strong>When there is no EC3 result for an EPD:</strong> Data is shown only for EPDs that have been successfully digitized by EC3 and for which sufficient category data is available. <a href='https://drive.google.com/file/d/1EysSAwUYApcAAcMCjXBYWa3FKEV4oq2d/view' target='_blank'>Read the EC3 methodology</a>.</i></p>
                  </div>
              </div>
              <div class='right'>
                  <img src='/assets/images/ec3-tooltip-graph-v3.png' alt='Graph' width='320' />
                  <p><strong>AECOs: To compare materials by performance attributes and location, log into the EC3 tool at <a href='http://BuildingTransparency.org' target='_blank'>BuildingTransparency.org</a>.</strong></p>
                  <p><strong>MFRs: To improve your EC3 results, or if there’s no result displayed for your EPD, or you need to create an EPD(s), <br/><a href='http://www.sustainableminds.com/contact-us' target='blank'>contact Sustainable Minds</a> about our Data & EPD services.</strong></p>
              </div>
          </div>
        `,
      }
    },
    epdTooltipContent() {
      return {
        title: `
           <div class='tooltip-epd'>
            <h1>ISO 14025 Type III environmental declarations</h1>
            <h2>Program</h2>
            <p>ISO 14025 is the international standard for creating Type III environmental declarations. Organizations who conduct Type III environmental programs are called program operators (PO). The introduction to the standard states POs &ldquo;may refer to declarations by any name, such as eco-profile, environmental product declaration (EPD), environmental profile and Eco-Leaf.&rdquo;</p>
            <p>&nbsp;</p>
            <p><b>Sustainable Minds' brand of Type III environmental declaration is the <a href='http://www.sustainableminds.com/transparency-products/transparency-reports' target='_blank'>SM Transparency Report&trade;</a>.</b> Delivered in the cloud, in three easy to understand pages, it provides all the functional, environmental and material ingredient information to make a purchase decision.</p>
            <h2>Industry Average and optimized EPDs</h2>
            <p>All EPDs in the catalog are product-specific unless indicated to be industry average <strong>(IA)</strong>. EPDs eligible to earn optimization credits are indicated as <strong>EPD+Opt</strong>.</p>
            <p>&nbsp;</p>
            <p><i>Learn more about <a href='https://www.iso.org/obp/ui/#iso:std:iso:14025:ed-1:v1:en' target='_blank'>ISO 14025 Type III environmental declarations</a>.</i></p>
            <h2>Scope</h2>
            <p>Scope of the LCA is defined by the life cycle stages included. Cradle to gate is the minimum required for compliance with green building rating systems.</p>
            <p>&nbsp;</p>
            <p class='rollover_wrap'><span class='rollover_label_wide'><b>C2Gate</b></span><span class='rollover_text_short'>Cradle to gate – from extraction of raw materials to the factory gate</span></p>
            <p class='rollover_wrap'><span class='rollover_label_wide'><b>C2 Gate w/options</b></span><span class='rollover_text_short'>Cradle to gate with options – additional life cycle stages specified either in the PCR w/options or voluntartily reported</span></p>
            <p class='rollover_wrap'><span class='rollover_label_wide'><b>C2Grave</b></span><span class='rollover_text_short'>Cradle to grave – from extraction of raw materials through use and end of life</span></p>

            <h2>Region</h2>
            <p>The Type III environmental declaration is done in accordance with the reporting requirements for that region: <b>N. America, Europe, Asia or Global</b></p>

            <h2>CO2e</h2>
            <p>CO2e, or carbon dioxide equivalent, is a standard unit for measuring carbon footprints. The idea is to express the impact of each different greenhouse gas in terms of the amount of CO2 that would create the same amount of warming.</p>
          </div>    
        `,
      }
    },
    filteredDivision: {
      get() {
        return this.$store.state.filteredDivision;
      },
      set(value) {
        this.$store.commit('updateFilteredDivision', value);
      }
    },

    filteredSection: {
      get() {
        return this.$store.state.filteredSection;
      },
      set(value) {
        this.$store.commit('updateFilteredSection', value);
      }
    },

    filteredMasterFormats: {
      get() {
        return this.$store.state.filteredMasterFormats;
      },
      set(value) {
        this.$store.commit('updateFilteredMasterFormats', value);
      }
    },

    filteredRatingSystems: {
      get() {
        return this.$store.state.filteredRatingSystems;
      },
      set(value) {
        this.$store.commit('updateFilteredRatingSystems', value);
      }
    },

    filteredDisclosures: {
      get() {
        return this.$store.state.filteredDisclosures;
      },
      set(value) {
        this.$store.commit('updateFilteredDisclosures', value);
      }
    },

    filteredEc3: {
      get() {
        return this.$store.state.filteredEc3;
      },
      set(value) {
        this.$store.commit('updateFilteredEc3', value);
      }
    },

    filteredRegulations: {
      get() {
        return this.$store.state.filteredRegulations;
      },
      set(value) {
        this.$store.commit('updateFilteredRegulations', value);
      }
    },

    keywords: {
      get() {
        return this.$store.state.filterKeywords;
      },
      set(value) {
        this.$store.commit('updateFilterKeywords', value);
      }
    },

    // Map Vuex state computed properties

    ...mapState([
      'filteredBrands',
      'filteredBrandsAvailable',
      'filteredBrandsVisible',
      'loadingResults'
    ]),

    // Local properties

    enabled: function() {
      //  return this.filteredDivision && this.filteredDivision.length > 0 && !this.loading;
      return !this.loading;
    },

    buttonEnabled: function() {
      return this.keywords != '' && !this.loading;
    },

    sectionEnabled: function() {
      return this.filteredSection && this.filteredSection.length > 0 && !this.loading;
    },

    divisionOnlyEnabled: function() {
      return this.enabled && (this.filteredMasterFormats.length === 1 && this.filteredMasterFormats[0] === this.filteredDivision);
    },

    brandsEnabled: function() {
      return this.filteredBrandsAvailable.length > 0;
    },

    requestOnLoad: function() {
      //console.log('requestOnLoad(): number: ' + this.request.number);
      var loading = this.request && (this.request.hasOwnProperty('number') && this.request.number !== null);
      //return this.request && (this.request.hasOwnProperty('number') && (this.request.number !== null && this.request.number !== ''));
      console.log(loading);
      return loading;
    }
  },

  methods: {

    ...mapMutations([
      'toggleFilterModal',
      'closeFilterModal',
      'updateFilteredBrands',
      'updateFilteredBrandsAvailable',
      'updateFilteredResults',
      'updateFilteredPageInfo',
      'clearFilteredBrands',
      'clearFilteredBrandsAvailable',
      'clearFilteredResults',
      'setLoadingResults'
    ]),

    ...mapActions([
      'fetchFilteredResults'
    ]),

    fetchMasterFormats() {
      console.log('fetchMasterFormats()');
      this.initialized = false;

      HTTP.get('/masterformat')
        .then(response => {
          // TODO why is response.data empty when Blitz is enabled for /masterformat?
          console.log('response.data',response.data);
          this.divisionOptions = response.data.data;
          this.initialized = true;

          if(this.requestOnLoad) {
            if (this.request.number !== '') {
              // automatically pre-populate section if request
              this.fetchMasterFormatTree(parseInt(this.filteredDivision));
            }
          }
        })
        .catch(e => {
          alert(e);
        });
    },

    fetchMasterFormatTree(id) {
      console.log('fetchMasterFormatTree(' + id + ')');
      let index = this.masterFormatOptions.findIndex(function (obj) { return obj.id === id; });

      if(index > -1) {
        // use stored data
        this.updateMasterFormatTree();
      } else {
        this.loading = true;
        // fetch the data
        HTTP.get(`/masterformat/${id}`)
          .then(response => {
            if(response.data.data.length) {
              this.masterFormatOptions.push(response.data.data[0]);
              this.updateMasterFormatTree();
            }
            this.loading = false;
          })
          .catch(e => {
            alert(e);
          });
        }
    },

    fetchRatingSystems() {
      HTTP.get('/ratingsystems')
        .then(response => {
          this.ratingSystemOptions = response.data.data;
        })
        .catch(e => {
          alert(e);
        });
    },

    fetchEpds() {
      HTTP.get('/search/filters/epds.json')
        .then(response => {
          this.epdOptions = response.data.data;
        })
        .catch(e => {
          alert(e);
        });
    },

    fetchMaterials() {
      HTTP.get('/search/filters/materials.json')
        .then(response => {
          this.materialOptions = response.data.data;
        })
        .catch(e => {
          alert(e);
        });
    },

    fetchEc3() {
      HTTP.get('/ec3')
        .then(response => {
          this.ec3Options = response.data.data;
        })
        .catch(e => {
          alert(e);
        });
    },

    fetchRegulations() {
      HTTP.get('/regulations')
        .then(response => {
          this.regulationOptions = response.data.data;
        })
        .catch(e => {
          alert(e);
        });
    },

    // section options

    updateMasterFormatTree() {
      console.log('updateMasterFormatTree()');
      let filteredDivision = parseInt(this.filteredDivision);
      let index = this.masterFormatOptions.findIndex(function (obj) { return obj.id === filteredDivision; });

      if(index > -1) {
        this.currentDivisionTree = this.masterFormatOptions[index];
        this.divisionTreeOpen = true;
      } else {
        this.currentDivisionTree = [];
        this.divisionTreeOpen = false;
      }

      if(this.requestOnLoad) {
        // automatically update filters and results if request
        this.updateRequestResults();
      }
    },

    // event handlers

    // handle division select
    selectDivision($event) {
      this.clearRequest();
      this.clearSubselections();
      this.clearBrands();
      //this.clearRatingSystems();
      //this.clearKeywords();
      this.fetchMasterFormatTree(this.filteredDivision);
      this.filteredMasterFormats = [this.filteredDivision];
      this.updateResults();
    },

    // handle section click
    toggleGroup($event) {
      let toggle = $event.target;
      let group = toggle.getAttribute('data-group');
      let opening = !!(toggle.getAttribute('data-state') !== 'open');
      let state = opening ? 'open' : 'closed';

      if(this.filteredSection === group) {
        // reset the request section if manually closed
        this.filteredSection = "";
      }

      toggle.setAttribute('data-state', state);
      this.toggleChildren(group, opening);
      this.clearBrands();
      this.updateResults();
    },

    // handle toggle all click
    toggleAll($event) {
      let toggle = $event.target;
      let group = toggle.getAttribute('data-group');
      let opening = !!(toggle.getAttribute('data-state') !== 'open');
      let state = opening ? 'open' : 'closed';
      let selections = this.filteredMasterFormats;

      console.log('toggleAll', group, opening)

      // TODO: we're sending the division to the search in the MF number parameter instead of leaving it blank like when unchecking the box 
      // because of logic in toggleChildren()

      // set selections
      this.filteredMasterFormats = selections;

      toggle.setAttribute('data-state', state);
      
      if (state == 'open') {
        toggle.text = 'Clear all';
      } else {
        toggle.text = 'Select all';
      }

      this.toggleChildren(group, opening, true);
      this.clearBrands();
      this.updateResults();
    },

    // handle checkbox click
    toggleCheckbox($event) {
      console.log('toggleCheckbox');
      let checkbox = $event.target;

      if(checkbox.hasAttribute('data-parent')) {
        this.toggleParents($event);
      }

      // update the MasterFormat model
      let selections = this.filteredMasterFormats;
      let id = checkbox.getAttribute('value');
      let title = checkbox.getAttribute('data-title');

      // console.log("toggle: " + this.filteredMasterFormats);
      // console.log("checkbox: " + checkbox.checked + " val: " + id);

      if (checkbox.checked) {
        selections.push(id);
        this.filteredMasterFormatTitles.push( title );
      } else {
        selections = selections.filter(function(val) { return val !== id; });
        this.filteredMasterFormatTitles = this.filteredMasterFormatTitles.filter(function(val) { return val !== title });
      }

      this.filteredMasterFormats = selections;
      this.updateResults();
    },

    // adds or removes all children in the tree
    toggleChildren(group, opening, excludeDivision) {
      console.log('toggleChildren');
      let selections = this.filteredMasterFormats;
      let checkboxes = [...document.querySelectorAll('input[data-group="'+group+'"]')];

      // remove division
      let divisionId = this.filteredDivision;
      let divisionIndex = selections.indexOf(divisionId);
      if(divisionIndex > -1 && opening) {
        selections = selections.filter(function(val) { return val !== divisionId; });
      }

      // add or remove all descendants
      checkboxes.forEach(element => {
        let id = element.getAttribute('value');
        let title = element.getAttribute('data-title');
        let selectedIndex = selections.indexOf(id);

        if(opening) {
          if(selectedIndex === -1) {
            selections.push(id);
            this.filteredMasterFormatTitles.push( title );
          }
        } else {
          if(selectedIndex > -1) {
            selections = selections.filter(function(val) { return val !== id; });
            this.filteredMasterFormatTitles = this.filteredMasterFormatTitles.filter(function(val) { return val !== title });
          }
        }
      });

      // if all closed default to top level division, 
      // unless we're specifically excluding the division, like when the Select All link is used (i.e. toggleAll())
      if(selections.length === 0 && !opening && !excludeDivision) {
        selections.push(divisionId);
      }

      // set selections
      this.filteredMasterFormats = selections;
    },

    // adds or removes all parents in the tree
    // as of 1/24
    // Checking a parent also checks all children
    // Unchecking a parent also unchecks all children
    // Checking a child does not change the parent
    // Unchecking a child does not change the parent
    toggleParents($event) {
      let checkbox = $event.target;
      let parent = checkbox.getAttribute('data-parent');
      let level = checkbox.getAttribute('data-level');
      let selections = this.filteredMasterFormats;
      let checkboxes = [...document.querySelectorAll('[data-parent="'+parent+'"]')];

      console.log('toggleParents: level ' + level);
      
      if(checkbox.checked === false) {
        // remove division
        let divisionId = this.filteredDivision;
        let divisionIndex = selections.indexOf(divisionId);
        if(divisionIndex > -1) {
          selections = selections.filter(function(val) { return val !== divisionId; });
        }

        // remove all parents and descendants
        checkboxes.forEach(element => {
          let id = element.getAttribute('value');
          let title = element.getAttribute('data-title');
          let selectedIndex = selections.indexOf(id);

          if(selectedIndex > -1 && (element.getAttribute('data-level') > level)) {
            selections = selections.filter(function(val) { return val !== id; });
            this.filteredMasterFormatTitles = this.filteredMasterFormatTitles.filter(function(val) { return val !== title });
          }
        });
      } else {
        let allSiblingsChecked = true;

        // add all descendants
        checkboxes.forEach(element => {
          let id = element.getAttribute('value');
          let title = element.getAttribute('title');
          let selectedIndex = selections.indexOf(id);

          if(selectedIndex === -1 && element.getAttribute('data-level') > level) {
            selections.push(id);
            this.filteredMasterFormatTitles.push( title );
          }

          // check each sibling, but not the parent
          // we're trying to see if all of the siblings are checked
          if(element.getAttribute('data-parent') != element.getAttribute('value')) {
            if(allSiblingsChecked && element.checked === false) {
              allSiblingsChecked = false;
            }
          }
        });

        // if all of our siblings are checked, check the parent
        // if(allSiblingsChecked) {
        //  selections.push(parent);
        //  this.filteredMasterFormatTitles.push( parent.getAttribute('data-title') );
        // }

      }

      // set selections
      this.filteredMasterFormats = selections;
    },

    // Helper to get simple (non-division) descendant checkbox groups
    getCheckboxDescendantsIds(checkbox) {
      let group = checkbox.getAttribute('data-group');
      let elems = document.querySelectorAll('[data-parent="'+group+'"]');
      let ids = Array.from(elems, el => el.getAttribute('value'));
      return ids;
    },

    // Helper to get simple (non-division) parent checkbox id
    getCheckboxParentId(checkbox) {
      let group = checkbox.getAttribute('data-parent');
      let elem = document.querySelector('[data-group="'+group+'"]');
      return elem ? elem.getAttribute('value') : null;
    },

    toggleRatingSystems($event) {
      let checkbox = $event.target;

      // update the Rating Systems model
      let selections = this.filteredRatingSystems;
      let id = checkbox.getAttribute('value');

      // console.log("toggle: " + this.filteredRatingSystems);
      // console.log("checkbox: " + checkbox.checked + " val: " + id);

      if (checkbox.checked) {
        selections.push(id);
      } else {
        selections = selections.filter(function(val) { return val !== id; });
      }

      this.filteredRatingSystems = selections;

      this.updateResults();
    },

    toggleDisclosures($event) {
      let checkbox = $event.target;
      let descendantsIds = this.getCheckboxDescendantsIds(checkbox);
      let parentId = this.getCheckboxParentId(checkbox);

      // update the Disclosures model
      let selections = this.filteredDisclosures;
      let id = checkbox.getAttribute('value');

      console.log("toggle: " + this.filteredDisclosures);
      // console.log("checkbox: " + checkbox.checked + " val: " + id);

      if (checkbox.checked) {
        selections.push(id);

        // add the parent id if needed
        //if(parentId && selections.indexOf(parentId) === -1) {
          // don't select the parent if a HPD child was selected
          //console.log('Checking parent', parentId);
          //if (parentId != 'healthProductDeclaration') {
          //  selections.push(parentId);
          //}
        //}

        // add any descendants and keep values unique
        // if (descendantsIds.length && checkbox.getAttribute('id') !== 'disclosure-healthProductDeclaration') {
        if (descendantsIds.length) {
          selections = [...new Set([...descendantsIds, ...selections])];
        }

      } else {
        selections = selections.filter(function(val) { return val !== id });

        // remove any descendants
        if (descendantsIds.length) {
          selections = selections.filter(function(val) { return !descendantsIds.includes(val) });
        }

        // uncheck any parents
        if (parentId) {
          selections = selections.filter(function(val) { return !parentId.includes(val) });
        }
      }

      this.filteredDisclosures = selections;

      this.updateResults();
    },

    toggleEc3($event) {
      let checkbox = $event.target;

      // update the EC3 model
      let selections = this.filteredEc3;
      let id = checkbox.getAttribute('value');

      if (checkbox.checked) {
        selections.push(id);
      } else {
        selections = selections.filter(function(val) { return val !== id; });
      }

      this.filteredEc3 = selections;

      this.updateResults();
    },

    toggleRegulations($event) {
      let checkbox = $event.target;

      // update the Regulations model
      let selections = this.filteredRegulations;
      let id = checkbox.getAttribute('value');

      // console.log("toggle: " + this.filteredRegulations);
      // console.log("checkbox: " + checkbox.checked + " val: " + id);

      if (checkbox.checked) {
        selections.push(id);
      } else {
        selections = selections.filter(function(val) { return val !== id; });
      }

      this.filteredRegulations = selections;

      this.updateResults();
    },

    submitKeywords() {
      this.updateResults();
    },

    sendGaEvent(category, action, label) {
      console.log('GA event – ' + category + ' | ' + action + ' | ' + label );

      this.$ga.event({
        eventCategory: category,
        eventAction: action,
        eventLabel: label
      });
    },

    sendGaEvents() {
      let category = 'search';
      //let category = 'Test';
      let action = '';
      let label = '';

      // 0) Send search event
      action = 'search';
      this.sendGaEvent(category, action, label);

      // 1) SEARCH MASTER FORMAT DIVISION EVENT
      if (this.filteredDivision) {
        action = 'division';
        label = '';

        // The selected division
        let filteredDivision = parseInt(this.filteredDivision);

        // Get the division's name and number
        let index = this.divisionOptions.findIndex(function (obj) { return obj.id === filteredDivision; });
        label = this.divisionOptions[index].number + ' ' + this.divisionOptions[index].title;

        this.sendGaEvent(category, action, label);
      }

      // 2) SEARCH KEYWORD EVENT
      if (this.keywords) {
        action = 'keyword';
        label = this.keywords;
        this.sendGaEvent(category, action, label);
      }

      // 3) SEARCH RATING SYSTEM EVENT
      if (this.filteredRatingSystems.length > 0) {
        action = 'ratingsystem'

        // Loop through the selected systems and get the name
        var i;
        for (i = 0; i < this.filteredRatingSystems.length; i++) {
          let filteredRatingSystem = this.filteredRatingSystems[i];
          // console.log(filteredRatingSystem,this.ratingSystemOptions);
          let index = this.ratingSystemOptions.findIndex(function (obj) { return obj.id === filteredRatingSystem; });
          label = this.ratingSystemOptions[index].title;
          this.sendGaEvent(category, action, label);
        }
      }

      // 4) SEARCH REGULATIONS EVENT
      if (this.filteredRegulations.length > 0) {
        action = 'regulation'

        // Loop through the selected disclosure and get the name
        var i;
        for (i = 0; i < this.filteredRegulations.length; i++) {
          let filteredRegulation = this.filteredRegulations[i];
          let index = this.regulationOptions.findIndex(function (obj) { return obj.id === filteredRegulation; });
          label = this.regulationOptions[index].title;
          this.sendGaEvent(category, action, label);
        }
      }

      // 5) SEARCH BRAND EVENT

      // TODO can't seem to get a list of filteredBrandsAvailable
      // console.log('filteredBrands:  ' + this.filteredBrands.length);
      // console.log('filteredBrandsAvailable:  ' + this.filteredBrandsAvailable.length);

      // if (this.filteredBrands.length > 0) {
      //   label = '';

      //   // Loop through the selected brands and get the name
      //   var i;
      //   for (i = 0; i < this.filteredBrands.length; i++) {
      //     let filteredBrand = this.filteredBrands[i];

      //     // index = this.brandOptions.findIndex(function (obj) { return obj.id === filteredBrand; });
      //     // label += this.f[index].title + ' | ';
      //   }

      //   // Trim off the trailing pipe and space
      //   label = label.slice(0, -2); 

      //   // this.sendGaEvent(category, action, label);
      // }

      // 5) SEARCH MASTERFORMAT SECTION EVENTS

      if (this.filteredMasterFormats.length > 0) {
        action = 'section';
        label = '';

        // Loop through the selected sections and fire a GA event
        this.filteredMasterFormatTitles.forEach(element => {
          // console.log( element );
          if (element !== null) {
            label = element;
            this.sendGaEvent(category, action, label);
          }
        });
      }

      // 6) SEARCH DISCLOSURE TYPE EVENT
      if (this.filteredDisclosures.length > 0) {
        action = 'disclosure'

        // Loop through the selected disclosure and get the name
        var i;
        for (i = 0; i < this.filteredDisclosures.length; i++) {
          let filteredDisclosure = this.filteredDisclosures[i];
          // console.log(filteredDisclosure);
          // This errors for descendants so just return the id
          let index = this.disclosureOptions.findIndex(function (obj) { return obj.id === filteredDisclosure; });
          label = this.disclosureOptions[index] ? this.disclosureOptions[index].title : filteredDisclosure;
          this.sendGaEvent(category, action, label);
        }
      }

      // 7) SEARCH EC3 TYPE EVENT
      if (this.filteredEc3.length > 0) {
        action = 'ec3'

        // Loop through the selected disclosure and get the name
        var i;
        for (i = 0; i < this.filteredEc3.length; i++) {
          let filteredEc3 = this.filteredEc3[i];
          console.log(filteredEc3);
          let index = this.ec3Options.findIndex(function (obj) { return obj.id === filteredEc3; });
          label = this.ec3Options[index].title;
          this.sendGaEvent(category, action, label);
        }
      }

    },

    clearFilters() {
      this.clearDivision();
      this.clearBrands();
      this.clearRatingSystems();
      this.clearDisclosures();
      this.clearEc3();
      this.clearRegulations();
      this.clearKeywords();
      this.clearResults();
      this.clearRequest();
    },

    clearDivision() {
      this.filteredDivision = "";
      this.filteredSection = "";
      this.clearMasterFormatTree();
    },

    clearSubselections() {
      this.filteredSection = "";
      this.clearMasterFormatTree();
    },

    clearMasterFormatTree() {
      this.filteredMasterFormats = [];
      this.currentDivisionTree = [];
      this.divisionTreeOpen = false;
      this.clearResults();
    },

    clearBrands() {
      this.closeFilterModal();
      this.clearFilteredBrands();
      this.clearFilteredBrandsAvailable();
    },

    clearRatingSystems() {
      this.filteredRatingSystems = [];
      let openToggle = document.querySelector('.filter-ratings a.collapsible-trigger.open');
      if(openToggle) {
        openToggle.click();
      }
    },

    clearDisclosures() {
      this.filteredDisclosures = [];
      let openToggle = document.querySelector('.filter-disclosures a.collapsible-trigger.open');
      if(openToggle) {
        openToggle.click();
      }
    },

    clearEc3() {
      this.filteredEc3 = [];
      let openToggle = document.querySelector('.filter-ec3 a.collapsible-trigger.open');
      if(openToggle) {
        openToggle.click();
      }
    },

    clearRegulations() {
      this.filteredRegulations = [];
      let openToggle = document.querySelector('.filter-regulations a.collapsible-trigger.open');
      if(openToggle) {
        openToggle.click();
      }
    },

    clearKeywords() {
      this.keywords = "";
    },

    clearResults() {
      this.clearFilteredResults();
    },

    clearRequest() {
      this.request = null;
    },

    // results callbacks

    updateResults() {
      console.log('updateResults()');
      this.$nextTick(function(){
        this.fetchFilteredResults();
        this.sendGaEvents();

        // let resultsEvent = new Event('resultsUpdated', { bubbles: true });
        // document.dispatchEvent(resultsEvent);

        this.request = null;
      });
    },

    updateRequestResults() {
      console.log('updateRequestResults()');
      // add all children of requested section before updating
      // results and unset the request after initial population.
      this.$nextTick(function(){
        if(this.filteredSection.length) {
          this.toggleChildren(this.filteredSection, true);
        }
        this.fetchFilteredResults();
        this.sendGaEvents();
        this.clearRequest();

        let resultsEvent = new Event('resultsUpdated', { bubbles: true });
        document.dispatchEvent(resultsEvent);
      });
    },

    // utility functions

    filterEmpty(items) {
      if(items) {
        return items.filter(function(item) {
          return item.count > 0;
        });
      }
      return items;
    },

    formatNumbers(str) {
      if(str) {
        let pattern = /( 00)? 00$/g;
        str = str.replace(pattern, '');
      }
      return str;
    },

    // initialize

    initializeRequest() {
      console.log('initializeRequest()');
      if(this.request.hasOwnProperty('number')) {
        console.log('true');
        let numbers = this.request.number.split(',');

        // division is first number, section is second if passed
        this.filteredDivision = numbers[0];
        this.filteredMasterFormats = [this.filteredDivision];

        if(numbers.length > 1) {
          this.filteredSection = numbers[1];
        }

        if(this.request.hasOwnProperty('q')) {
          this.keywords = this.request.q;
        }
      } else {
        console.log('false');
        this.request = null;
      }
    },

    initializeFilters() {
      console.log('initializeFilters()');
      if(this.requestOnLoad) {
        this.setLoadingResults();
        this.initializeRequest();

        // If this is a keyword search only and not a MF search, trigger the request here...
        if (this.request.hasOwnProperty('q') && this.request.q !== '') {
          console.log('Keyword search only');
          this.updateRequestResults();
        } else {
          console.log('MF search');
        }
      } 

      // Otherwise the request will get triggered here via fetchMasterFormatTree()
      this.fetchMasterFormats();
      this.fetchRatingSystems();
      this.fetchEpds();
      this.fetchMaterials();
      this.fetchEc3();
      this.fetchRegulations();
    }
  },

  directives: {
    collapse: {
      bind: function (el, binding) {
        el.addEventListener('click', function(e){
          e.preventDefault();

          let target = document.getElementById(el.getAttribute('href').replace('#', ''));
          el.classList.toggle('open');
          el.parentNode.parentNode.classList.toggle('open');
          target.classList.toggle('show');
        });
      }
    }
  },

  beforeMount() {
    this.initializeFilters();
  },

  components: {
    PerfectScrollbar
  }
};
</script>
