/*
	http-common.js
*/

import axios from 'axios';

const BASE_URL = window.BASE_URL || 'http://localhost:3000/';

export const HTTP = axios.create({
  baseURL: BASE_URL,
  responseType: 'json'
});